import { memo } from 'react';

import * as Experiments from '@Queries/Experiments';
import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import Button from '@UI/Button';
import useMedias from '@Hooks/useMedias';
import useTranslation from '@Queries/useTranslation';
import FilterSelectedValues from '@Components/FilterSelectedValues';
import Labels from '../common/Labels';
import PopularLinks from '../common/PopularLinks';
import QuickFilters from '../QuickFilters';
import styles from './MattressesFilters.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { PopularLinkData } from '@Types/Category';
import type { GroupData } from '../typings';
import type { FilterView } from '@Types/Filters';

export interface FiltersProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  count?: number;
  groups?: GroupData[];
  popularLinks?: PopularLinkData[];
  onOpen?: (e: MouseEvent, id: string, filterView: FilterView) => void;
}

const MattressesFilters: FC<FiltersProps> = (props) => {
  const { className, count, groups, popularLinks = [], onOpen, ...restProps } = props;
  const { isDesktop, isOnlyDesktop } = useMedias();
  const filtrator = CatalogFiltrator.useFiltrator();
  const priorityFilters = CatalogFiltrator.usePriorityFilters();
  const appliedValues = CatalogFiltrator.useAppliedValues();
  const featureFlags = Experiments.useFeatureFlags();
  const { t } = useTranslation();

  return (
    <div {...restProps} className={className}>
      <div className={styles.filtersWrapper}>
        <div className={styles.wrapperActions}>
          <div className={styles.actions}>
            {filtrator.filters && (
              <QuickFilters priorityFilters={priorityFilters} onOpen={onOpen} />
            )}
            {typeof count === 'number' && isOnlyDesktop && (
              <div className={styles.count}>
                {t('ui.filters.found')}
                <span className='ProductRange'>{count}</span>
              </div>
            )}
          </div>
          {appliedValues.length > 0 && (
            <FilterSelectedValues
              className={styles.selectedValues}
              values={appliedValues}
              filtratorId='catalog'
            />
          )}
        </div>

        <div className={styles.wrapperLabels}>
          {featureFlags.mattressQuizAvailable && (
            <Button
              wide
              theme='secondary'
              className={styles.buttonMattresses}
              to='/promo/mattrasses'
              target='_blank'
            >
              <div className={styles.iconMattresses} />
              {t('ui.choose-mattress')}
            </Button>
          )}
        </div>
      </div>

      {(popularLinks.length > 0 || isDesktop) && (
        <div className={styles.bottom}>
          {popularLinks.length > 0 && (
            <div className={styles.popularLinksWrapper}>
              <PopularLinks label={t('ui.filters.popular')} popularLinks={popularLinks} />
            </div>
          )}
          <Labels className={styles.labels} groups={groups} filterView='default' />
        </div>
      )}
    </div>
  );
};

export default memo(MattressesFilters);
